<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<style>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

/* Add your custom styles here */
body {
  color: #ffffff !important;
  background-color: #000000 !important;

}
</style>

<script>
export default {
  name: 'App',
};
</script>




