import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/dashboardView.vue";
import User from "../views/userView.vue";
import Badge from "../views/badgesView.vue";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    props: (route) => ({ groupId: route.query.groupId }),
  },
  {
    path: "/users",
    name: "User",
    component: User,
    props: (route) => ({ groupId: route.query.groupId }),
  },
  {
    path: "/badges",
    name: "Badge",
    component: Badge,
    props: (route) => ({ groupId: route.query.groupId }),
  },
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/dashboard",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
