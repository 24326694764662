<template>
  <div class="container-fill">
    <svg xmlns="http://www.w3.org/2000/svg" width="697" height="1177" viewBox="0 0 697 1177" fill="none"
      style="position: absolute; top: -70%; left: -80%; width: 280%; height: 180%; z-index: 1; pointer-events: none;">
      <g filter="url(#filter0_f_27_189)">
        <circle cx="71.5" cy="528.5" r="278.5" fill="#8A64E5" fill-opacity="0.10" />
      </g>
      <g filter="url(#filter1_f_27_189)">
        <circle cx="284.5" cy="764.5" r="162.5" fill="#6D98CC" fill-opacity="0.20" />
      </g>
      <defs>
        <filter id="filter0_f_27_189" x="-457" y="0" width="1057" height="1057" filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="125" result="effect1_foregroundBlur_27_189" />
        </filter>
        <filter id="filter1_f_27_189" x="-128" y="352" width="825" height="825" filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="125" result="effect1_foregroundBlur_27_189" />
        </filter>
      </defs>
    </svg>

    <!-- Navigation Bar -->
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark mb-3 sticky-top" style="padding-left: 20px">
      <router-link class="navbar-brand" to="/dashboard">
        <img src="https://statify.vmgware.dev/favicon.ico" alt="Camphouse Logo" height="30" width="30" />
        User Statistics
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <router-link class="nav-link" to="/dashboard">
              <i class="fa fa-tachometer"></i>
              Dashboard
            </router-link>
          </li>
        </ul>
      </div>
    </nav>

    <!-- Main Content -->
    <div id="main" class="container-fluid full-height">
      <!-- Welcome message -->
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-header">
              <h4>Badge Analytics</h4>
            </div>
            <div class="card-body">
              <p>Welcome to the Statify Analytics Panel.</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Badges Section -->
      <div class="container mt-4" v-if="badgeInfo">
        <div class="card" style="max-width: 400px; margin: 0 auto;">
          <div class="card-header">
            <h4>Roblox Badges</h4>
          </div>
          <div class="card-body">
            <!-- Display badge dynamically -->
            <div class="badge-item mb-3">
              <div class="row g-0">
                <div class="col-md-4">
                  <img :src="profileImageUrl" :alt="badgeInfo.name + ' Badge Image'"
                    class="img-fluid rounded-start badge-image" />
                </div>
                <div class="col-md-8">
                  <h1 class="badge-title">
                    <a :href="'https://www.roblox.com/badges/' + badgeid" target="_blank">{{ badgeInfo.name }}</a>
                  </h1>
                  <p class="card-text">{{ badgeInfo.description }}</p>
                  <p class="card-text"><small class="text-muted">Creator: {{ badgeInfo.creator }}</small></p>
                  <p class="card-text"><small class="text-muted">Created Date: {{ badgeInfo.createdDate }}</small></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <footer class="footer has-background-dark">
    <div class="content has-text-centered has-text-white">
      <p>
        Licensed under <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank"
          class="has-text-white">CC BY-NC-SA 4.0</a>
      </p>
    </div>
  </footer>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      badgeid: '',
      username: '',
      description: '',
      profileImageUrl: '',
      friendsCountApi: 0,
      followingsCountApi: 0,
      followersCountApi: 0,
      joinDate: '',
      badgeInfo: null, // Initialize badgeInfo as null
    };
  },
  mounted() {
    this.fetchBadgeInfo();
  },
  methods: {
    async fetchBadgeInfo() {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        this.badgeid = urlParams.get('badge');

        // Cache key for badge info and image
        const badgeInfoCacheKey = `badgeInfo-${this.badgeid}`;

        // Check local storage for cached badge info
        const cachedBadgeInfo = JSON.parse(localStorage.getItem(badgeInfoCacheKey));
        if (cachedBadgeInfo && Date.now() < cachedBadgeInfo.timestamp + 3600000) { // 3600000 ms = 1 hour
          this.badgeInfo = cachedBadgeInfo.data;
          this.profileImageUrl = cachedBadgeInfo.image;
        } else {
          // Fetch badge info from the server
          const response = await axios.get(`https://statify.vmgware.dev/api/badge-info/${this.badgeid}`);
          const badgeData = response.data;

          const image = `https://statify.vmgware.dev/api/image/webp/1000x1000/${badgeData.image.replace('https://tr.rbxcdn.com/', '')}`;

          // Fetch and cache badge image
          const imageResponse = await axios.get(image, { responseType: 'blob' });
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64Image = reader.result;
            // Cache badge info and image with timestamp
            localStorage.setItem(badgeInfoCacheKey, JSON.stringify({
              data: {
                name: badgeData.name,
                description: badgeData.description,
                creator: badgeData.creator,
                createdDate: new Date(badgeData.createdDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }),
              },
              image: base64Image,
              timestamp: Date.now()
            }));
            this.badgeInfo = {
              name: badgeData.name,
              description: badgeData.description,
              creator: badgeData.creator,
              createdDate: new Date(badgeData.createdDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }),
            };
            this.profileImageUrl = base64Image;
          };
          reader.readAsDataURL(imageResponse.data);
        }
      } catch (error) {
        console.error('Error fetching badge information:', error);
      }
    }
  }
};
</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bulma@1.0.1/css/bulma.min.css");
</style>

<style scoped>
app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Ensure app takes full viewport height */
}

.container-fill {
  flex: 1;
  /* Grow to take remaining vertical space */
  display: fill;
  flex-direction: column;
}

/* Custom CSS for dark mode */
:root {
  --background: #363636;
  --bs-card-bg: #ffffff !important;
  /* Dark background color */
  --foreground: #ffffff;
  /* White foreground text color */
  --card: #3d3d3d !important;
  /* Dark card background color */
  --card-foreground: #ffffff;
  /* Card foreground text color */
  --border: #212020;
  /* Border color */
}

#main {
  flex: 1;
  /* Take remaining vertical space */
  background-color: var(--background);
  /* Set background color */
  color: var(--foreground);
  /* Set text color */
  padding-bottom: 100px;
  /* Example padding */
}

/* Custom CSS for dark mode */
.card {
  background-color: #0f0e0e !important;
  color: var(--card-foreground) !important;
  border-color: #212020 !important;
  border-radius: 10px !important;
}

html {
  background-color: #0f0e0e !important;
}

.navbar {
  background-color: #0f0e0e !important;
}

.footer {
  background-color: #0f0e0e !important;
  height: 1px;
}

.column {
  padding-bottom: 10px !important;
}
</style>
