<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" width="697" height="1177" viewBox="0 0 697 1177" fill="none"
      style="position: absolute; top: -70%; left: -80%; width: 280%; height: 180%; z-index: 1; pointer-events: none;">
      <g filter="url(#filter0_f_27_189)">
        <circle cx="71.5" cy="528.5" r="278.5" fill="#8A64E5" fill-opacity="0.10" />
      </g>
      <g filter="url(#filter1_f_27_189)">
        <circle cx="284.5" cy="764.5" r="162.5" fill="#6D98CC" fill-opacity="0.20" />
      </g>
      <defs>
        <filter id="filter0_f_27_189" x="-457" y="0" width="1057" height="1057" filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="125" result="effect1_foregroundBlur_27_189" />
        </filter>
        <filter id="filter1_f_27_189" x="-128" y="352" width="825" height="825" filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="125" result="effect1_foregroundBlur_27_189" />
        </filter>
      </defs>
    </svg>


    <nav class="navbar navbar-expand-lg navbar-dark bg-dark mb-3 sticky-top" style="padding-left: 20px">
      <router-link class="navbar-brand" to="/dashboard">
        <img src="https://statify.vmgware.dev/favicon.ico" alt="Camphouse Logo" height="30" width="30" />
        User Statistics
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <router-link class="nav-link" to="/dashboard">
              <i class="fa fa-tachometer"></i>
              Dashboard
            </router-link>
          </li>
        </ul>
      </div>
    </nav>

    <div id="main" class="container-fluid full-height">
      <!-- Welcome message -->
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-header">
              <h4>User Analytics</h4>
            </div>
            <div class="card-body">
              <p>Welcome to the Statify Analytics Panel.</p>
            </div>
          </div>
        </div>
      </div>

      <!-- User Profile Information -->
      <div class="container mt-4">
        <div class="card" style="max-width: 400px; margin: 0 auto;">
          <div class="card-header">
            <h4>User Profile</h4>
          </div>
          <div class="card-body" style="padding: 10px;">
            <!-- User Profile Image -->
            <div class="profile-image">
              <img :src="profileImageUrl" :alt="username + ' Profile Image'" class="profile-image" />
            </div>
            <!-- User Details -->
            <div class="user-details">
              <h1 class="username">
                <a :href="'https://www.roblox.com/users/' + userId + '/profile'" target="_blank">{{ username }}</a>
              </h1>
              <p class="description">{{ description }}</p>
              <p class="friends-count">Friends: {{ friendsCountApi }}</p>
              <p class="followings-count">Following: {{ followingsCountApi }}</p>
              <p class="followers-count">Followers: {{ followersCountApi }}</p>
              <p class="join-date">Join Date: {{ joinDate }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- User Badges Section -->
      <div class="container mt-4">
        <div class="card">
          <div class="card-header">
            <h4>User Badges</h4>
          </div>
          <div class="card-body badges-container">
            <div v-if="badges.length" class="badges-scroll">
              <div v-for="badge in badges" :key="badge.id" class="badge-item">
                <img :src="badge.icon" :alt="badge.name" class="badge-icon" />
                <a :href="'https://statify.vmgware.dev/badges?badge=' + badge.id" target="_blank" class="badge-title">{{
                badge.name }}</a>
              </div>
            </div>
            <div v-else>
              <p>No badges found for this user.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      userId: '',
      username: '',
      description: '',
      profileImageUrl: '',
      friendsCountApi: 0,
      followingsCountApi: 0,
      followersCountApi: 0,
      joinDate: '',
      badges: []
    };
  },
  mounted() {
    this.fetchUserProfile();
    this.fetchUserBadges();
  },
  methods: {
    async fetchUserProfile() {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        this.userId = urlParams.get('userid');
        const response = await axios.get(`https://statify.vmgware.dev/api/user-profile/${this.userId}`);
        const userData = response.data;

        this.username = userData.username;
        this.description = userData.description;

        const imageUrl = `https://statify.vmgware.dev/api/image/webp/400x300/${userData.profileImageUrl.replace('https://tr.rbxcdn.com/', '')}`;

        // Check local storage for cached image with timestamp
        const cachedData = JSON.parse(localStorage.getItem(imageUrl));
        if (cachedData && Date.now() < cachedData.timestamp + 3600000) { // 3600000 ms = 1 hour
          this.profileImageUrl = cachedData.image;
        } else {
          // Fetch the image from server if not cached or expired
          const imageResponse = await axios.get(imageUrl, { responseType: 'blob' });
          const reader = new FileReader();

          reader.onloadend = () => {
            const base64Image = reader.result;
            localStorage.setItem(imageUrl, JSON.stringify({
              image: base64Image,
              timestamp: Date.now()
            }));
            this.profileImageUrl = base64Image;
          };

          reader.readAsDataURL(imageResponse.data);
        }

        this.friendsCountApi = userData.friendsCountApi;
        this.followingsCountApi = userData.followingsCountApi;
        this.followersCountApi = userData.followersCountApi;
        this.joinDate = userData.joinDate;
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    },

    async fetchUserBadges() {
      const cacheKey = `userBadges-${this.userId}`;
      const cachedBadges = JSON.parse(localStorage.getItem(cacheKey));

      if (cachedBadges && Date.now() < cachedBadges.timestamp + 3600000) { // 3600000 ms = 1 hour
        // Use cached badges
        this.badges = cachedBadges.data;
      } else {
        try {
          const response = await axios.get(`https://statify.vmgware.dev/api/user-badges/${this.userId}`);
          const badges = response.data;

          // Fetch additional badge info for each badge
          const badgePromises = badges.map(async (badge) => {
            const badgeInfoResponse = await axios.get(`https://statify.vmgware.dev/api/badge-info/${badge.id}`);
            const badgeInfo = badgeInfoResponse.data;

            return {
              id: badge.id,
              name: badgeInfo.name || badge.name,
              icon: badgeInfo.image,
            };
          });

          const badgeData = await Promise.all(badgePromises);

          // Cache the badge data with a timestamp
          localStorage.setItem(cacheKey, JSON.stringify({
            data: badgeData,
            timestamp: Date.now()
          }));

          this.badges = badgeData;
        } catch (error) {
          console.error('Error fetching user badges:', error);
        }
      }
    }
  }
};
</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bulma@1.0.1/css/bulma.min.css");

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Ensure app takes full viewport height */
}

.container-fill {
  flex: 1;
  /* Grow to take remaining vertical space */
  display: fill;
  flex-direction: column;
}

/* Custom CSS for dark mode */
:root {
  --background: #363636;
  --bs-card-bg: #ffffff !important;
  /* Dark background color */
  --foreground: #ffffff;
  /* White foreground text color */
  --card: #3d3d3d !important;
  /* Dark card background color */
  --card-foreground: #ffffff;
  /* Card foreground text color */
  --border: #212020;
  /* Border color */
}

#main {
  position: relative;
  z-index: 1;
  flex: 1;
  /* Take remaining vertical space */
  background-color: var(--background);
  /* Set background color */
  color: var(--foreground);
  /* Set text color */
  padding-bottom: 100px;
  /* Example padding */
}

/* Custom CSS for dark mode */
.card {
  background-color: #0f0e0e !important;
  color: var(--card-foreground) !important;
  border-color: #212020 !important;
  border-radius: 10px !important;
}

html {
  background-color: #0f0e0e !important;
}

.navbar {
  background-color: #0f0e0e !important;
}

.footer {
  background-color: #0f0e0e !important;
  height: 1px;
}

.column {
  padding-bottom: 10px !important;
}
</style>

<style scoped>
body {
  color: #ffffff !important;
  background-color: #000000 !important;
}

.profile-image {
  width: 90px !important;
  height: 90px !important;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.username {
  font-size: 14px;
  color: #e4e6e5;
  text-decoration: none;
}

.username:hover {
  font-size: 14px;
  color: #c9cccb;
  text-decoration: none;
}

.card {
  color: #ffffff !important;
}

.friends-count,
.followings-count,
.followers-count,
.join-date {
  font-size: 14px;
  color: #c9cccb;
  margin: 5px 0;
}

.badges-container {
  overflow-x: auto;
  scrollbar-width: thin;
  /* Firefox */
  scrollbar-color: #4a4a4a #2e2e2e;
  /* Firefox */
}

.badges-scroll {
  display: flex;
  flex-wrap: nowrap;
}

.badge-item {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  margin-right: 20px;
}

.badge-icon {
  width: 64px;
  height: 64px;
  margin-right: 10px;
}

.badge-title {
  font-size: 14px;
  color: #e4e6e5;
  text-decoration: none;
}

.badge-title:hover {
  color: #c9cccb;
}

/* Webkit Scrollbar */
.badges-container::-webkit-scrollbar {
  height: 8px;
}

.badges-container::-webkit-scrollbar-track {
  background: #2e2e2e;
  border-radius: 4px;
}

.badges-container::-webkit-scrollbar-thumb {
  background-color: #4a4a4a;
  border-radius: 4px;
  border: 2px solid #2e2e2e;
}

.badges-container::-webkit-scrollbar-thumb:hover {
  background-color: #6a6a6a;
}
</style>
