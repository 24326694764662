<template>
  <div id="app" :class="{ 'has-background-dark': isDarkMode, 'has-text-white': isDarkMode }">

    <svg xmlns="http://www.w3.org/2000/svg" width="697" height="1177" viewBox="0 0 697 1177" fill="none"
      style="position: absolute; top: -70%; left: -80%; width: 300%; height: 200%; z-index: 1; pointer-events: none;">
      <g filter="url(#filter0_f_27_189)">
        <circle cx="71.5" cy="528.5" r="278.5" fill="#8A64E5" fill-opacity="0.10" />
      </g>
      <g filter="url(#filter1_f_27_189)">
        <circle cx="284.5" cy="764.5" r="162.5" fill="#6D98CC" fill-opacity="0.20" />
      </g>
      <defs>
        <filter id="filter0_f_27_189" x="-457" y="0" width="1057" height="1057" filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="125" result="effect1_foregroundBlur_27_189" />
        </filter>
        <filter id="filter1_f_27_189" x="-128" y="352" width="825" height="825" filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="125" result="effect1_foregroundBlur_27_189" />
        </filter>
      </defs>
    </svg>

    <!-- Navbar -->
    <nav class="navbar is-dark is-fixed-top" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <router-link class="navbar-item has-text-white" to="/dashboard">
          <img src="https://statify.vmgware.dev/favicon.ico" alt="Camphouse Logo" height="30" width="30" />
          <span>Group Statistics</span>
        </router-link>
        <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" @click="toggleNavbar">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div :class="['navbar-menu', { 'is-active': navbarOpen }]">
        <div class="navbar-end">
          <router-link class="navbar-item has-text-white" to="/dashboard">
            <span class="icon">
              <i class="fa fa-tachometer"></i>
            </span>
            <span>Dashboard</span>
          </router-link>
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link has-text-white">
              <span class="icon">
                <i class="fa fa-plus"></i>
              </span>
              <span>Add Group</span>
            </a>
            <div class="navbar-dropdown is-dark">
              <div class="navbar-item">
                <label for="groupId" class="label has-text-white">Group ID:</label>
                <input type="text" class="input is-dark" id="groupId" v-model="newGroupId"
                  placeholder="Enter Group ID" />
              </div>
              <div class="navbar-item">
                <button class="button is-primary" @click="addGroup">Add</button>
              </div>
            </div>
          </div>
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link has-text-white">
              <span class="icon">
                <i class="fa fa-list"></i>
              </span>
              <span>Tracked Groups</span>
            </a>
            <div class="navbar-dropdown is-dark scrollable-list">
  <a class="navbar-item" v-for="group in trackedGroups" :key="group.groupId"
    :href="group.link" target="_blank" rel="noopener noreferrer">
    {{ group.name }}
  </a>
</div>

          </div>
        </div>
      </div>
    </nav>

    <!-- Error message section -->
    <div v-if="errorOccurred" class="container mt-6 pt-6">
      <div class="notification is-danger">
        Group not found. Please check the Group ID in the URL or try again later.
      </div>
    </div>

    <!-- Main content -->
    <div id="main" :data-theme="isDarkMode ? 'dark' : 'light'" class="container mt-6 pt-6">
      <!-- Welcome message -->
      <div class="columns">
        <div class="column">
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">Group Analytics</p>
            </header>
            <div class="card-content">
              <div class="content">
                <p>Welcome to the Statify Analytics Panel.</p>
                <!-- <p>
                  The current version of the application is
                  <strong>3f8677b</strong>.
                </p> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Statistics cards -->
      <div class="columns">
        <div class="column">
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">
                <span class="icon">
                  <i class="fa fa-users"></i>
                </span>
                <span>Users</span>
              </p>
            </header>
            <div class="card-content">
              <div class="content">
                <h1>{{ formatNumber(groupInfo.memberCount) }}</h1>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">
                <span class="icon">
                  <i class="fa fa-file"></i>
                </span>
                <span>Statistics</span>
              </p>
            </header>
            <div class="card-content chartcontain">
              <div class="content">
                <div class='chartcontain' id="chartContainer"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">
                <span class="icon">
                  <i class="fa fa-info"></i>
                </span>
                <span>Group Information</span>
              </p>
            </header>
            <div class="card-content">
              <div class="content">
                <div class="info-item" style="display: flex; justify-content: center; align-items: center;">
                  <img :src="groupIcon" :alt="groupInfo.groupName + ' Group Icon'" class="image is-128x128"
                    style="border: 2px solid #000; border-radius: 10px; filter: blur(10px);" loading="lazy"
                    onerror="this.src='https://statify.vmgware.dev/LoadingImage.png'"
                    onload="setTimeout(() => unblurImage(this), 1000);" />
                </div>
                <div class="info-item">
                  <span class="icon-text">
                    <span class="icon">
                      <i class="fa fa-users"></i>
                    </span>
                    <span>Group Name: {{ groupInfo.groupName }}</span>
                  </span>
                </div>
                <div class="info-item">
                  <span class="icon-text">
                    <span class="icon">
                      <i class="fa fa-info-circle"></i>
                    </span>
                    <span>Description:</span>
                  </span>
                  <div class="content">
                    <span>{{ groupInfo.description }}</span>
                  </div>
                </div>
                <div class="info-item">
                  <span class="icon-text">
                    <span class="icon">
                      <i class="fa fa-user"></i>
                    </span>
                    <span>Owner User: <a class="link"
                        :href="'https://statify.vmgware.dev/users?userid=' + groupInfo.ownerUserId">Roblox
                        Profile</a></span>
                  </span>
                </div>
                <div class="info-item">
                  <span class="icon-text">
                    <span class="icon">
                      <i class="fa fa-link"></i>
                    </span>
                    <span>Roblox Group: <a class="link" :href="'https://www.roblox.com/groups/' + groupInfo.groupLink">Roblox
                        Group</a></span>
                  </span>
                </div>
                <div class="info-item">
                  <span class="icon-text">
                    <span class="icon">
                      <i class="fa fa-calendar"></i>
                    </span>
                    <span>Created: {{ new Date(groupInfo.created).getFullYear() }}</span>
                  </span>
                </div>
                <div class="info-item">
                  <span class="icon-text">
                    <span class="icon">
                      <i class="fa fa-refresh"></i>
                    </span>
                    <span>Stats Updated: {{ formatRelativeTime(timestamp) }}</span>
                  </span>
                </div>
                <div class="info-item">
                  <span class="icon-text">
                    <span class="icon">
                      <i class="fa fa-check-circle"></i>
                    </span>
                    <span>Has Verified Badge: {{ groupInfo.hasVerifiedBadge }}</span>
                  </span>
                </div>
                <div class="info-item">
                  <span class="icon-text">
                    <span class="icon">
                      <i class="fa fa-users"></i>
                    </span>
                    <span>Most Recent Members:</span>
                  </span>
                  <div id="membersList" class="scrollable-list"></div>
                  <div class="buttons">
                    <button id="refreshButton" class="button is-info is-small">
                      <span class="icon">
                        <i class="fa fa-cog"></i>
                      </span>
                      <span>Refresh</span>
                    </button>
                    <span id="countdownTimer" class="tag is-dark">Refreshing in <span id="countdown"> 10</span>
                      seconds</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <footer class="footer has-background-dark">
      <div class="content has-text-centered has-text-white">
        <p>
          Licensed under <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank"
            class="has-text-white">CC BY-NC-SA 4.0</a>
        </p>
      </div>
    </footer>
  </div>
</template>


<script>
import axios from "axios";
import Highcharts from "highcharts";
import moment from "moment"; // Import the moment library
import Exporting from "highcharts/modules/exporting";
import ExportData from "highcharts/modules/export-data";

// Initialize the exporting module
Exporting(Highcharts);
ExportData(Highcharts);

export default {
  data() {
    return {
      chartData: [],
      chartLabels: [],
      milestones: [],
      newGroupId: "",
      groupIcon: "",
      timestamp: null,
      trackedGroups: [],
      groupInfo: {
        groupName: "",
        description: "",
        ownerUserId: "",
        created: "",
        hasVerifiedBadge: "",
        memberCount: "",
        link: "",
        groupLink: "",
      },
    };
  },
  mounted() {
    this.fetchGroups();
    const urlParams = new URLSearchParams(window.location.search);
    const groupId = urlParams.get("groupid");
    if (groupId) {
      this.fetchData(groupId);
      this.grabGroupTimestamp(groupId);
      this.grabGroupIcon(groupId);
      this.getRecentMembers(groupId);
      // Initialize the page and countdown
      document.getElementById("refreshButton").addEventListener("click", () => {
        const refreshButton = document.getElementById("refreshButton");
        refreshButton.disabled = true; // Disable the button
        console.log("Starting refresh");
        this.startCountdown(5, groupId); // Set the countdown duration in seconds

        // Set a timeout to re-enable the button after 5 seconds
        setTimeout(() => {
          refreshButton.disabled = false; // Enable the button
        }, 5000); // 5 seconds in milliseconds
      });
    } else {
      console.error("No groupId provided in the URL.");
    }
  },
  watch: {
    $route(to, from) {
      if (to.query.groupid !== from.query.groupid) {
        // Reload the page if the groupID parameter changes
        location.reload();
      }
    },
  },
  methods: {
    getRecentMembers(groupId) {
      const fetchTimeout = 10000; // 10 seconds in milliseconds

      // Create a promise that resolves when the fetch is successful
      const fetchData = fetch(
        `https://statify.vmgware.dev/api/recent-members/${groupId}`
      ).then((response) => response.json());

      // Create a promise that rejects if the fetch times out
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => {
          reject(new Error("Fetch request timed out"));
        }, fetchTimeout);
      });

      // Use Promise.race to handle the first promise that resolves (successful fetch) or rejects (timeout)
      Promise.race([fetchData, timeoutPromise])
        .then((data) => {
          const membersList = document.getElementById("membersList");
          membersList.innerHTML = ""; // Clear previous content

          data.recentMembers.forEach((member) => {
            const memberItem = document.createElement("div");
            memberItem.className = "member-item";

            const profileImage = document.createElement("img");
            profileImage.alt = member.username + " Profile Picture";
            profileImage.className = "profile-image";

            // Check if the profileImageUrl is blank or null
            if (!member.profileImageUrl) {
              setTimeout(() => {
                // Set the source to the loading image URL after 1 second
                profileImage.src =
                  "https://statify.vmgware.dev/LoadingImage.png";
              }, 1000);
            } else {
              // Remove the "https://tr.rbxcdn.com/" part from the image URL
              const imageUrl = `https://statify.vmgware.dev/api/image/webp/400x300/${member.profileImageUrl.replace('https://tr.rbxcdn.com/', '')}`;
              profileImage.src = imageUrl;
            }

            const usernameLink = document.createElement("a");
            usernameLink.href = `https://statify.vmgware.dev/users?userid=${member.userId}`; // Create the user profile URL
            usernameLink.target = "_blank"; // Open the link in a new tab
            usernameLink.textContent = member.username;
            usernameLink.className = "username";

            memberItem.appendChild(profileImage);
            memberItem.appendChild(usernameLink);
            membersList.appendChild(memberItem);
          });
        })
        .catch((error) => {
          // Handle the error (e.g., display a timeout message)
          console.error("Error fetching recent members: ", error);
          const membersList = document.getElementById("membersList");
          membersList.innerHTML =
            "Fetch request timed out. Please try refreshing.";
        });
    },
    formatRelativeTime(timestamp) {
      if (timestamp) {
        const now = Math.floor(new Date().getTime() / 1000); // Current time in seconds
        const created = Math.floor(new Date(timestamp).getTime() / 1000); // Convert timestamp to Unix timestamp
        const diff = now - created;

        if (diff < 60) {
          return `a few seconds ago`;
        } else if (diff < 3600) {
          const minutes = Math.floor(diff / 60);
          return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
        } else {
          const hours = Math.floor(diff / 3600);
          return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
        }
      }
      return "Timestamp not available"; // Handle when timestamp is null
    },
    grabGroupTimestamp(groupId) {
      fetch(`https://statify.vmgware.dev/api/groups?groupId=${groupId}`)
        .then((response) => response.json())
        .then((data) => {
          this.timestamp = data.timestamp;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    async grabGroupIcon(groupId) {
      const cacheKey = `groupIcon-${groupId}`;
      const cachedIcon = JSON.parse(localStorage.getItem(cacheKey));

      if (cachedIcon && Date.now() < cachedIcon.timestamp + 3600000) { // 3600000 ms = 1 hour
        // Use the cached image
        this.groupIcon = cachedIcon.data;
      } else {
        try {
          const response = await fetch(`https://statify.vmgware.dev/api/groups?groupId=${groupId}`);
          const data = await response.json();
          const imageUrl = `https://statify.vmgware.dev/api/image/webp/500x500/${data.groupIcon.replace('https://tr.rbxcdn.com/', '')}`;

          // Fetch the image
          const imageResponse = await fetch(imageUrl);
          const imageBlob = await imageResponse.blob();

          // Convert image to base64
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64Image = reader.result;

            // Cache the image with a timestamp
            localStorage.setItem(cacheKey, JSON.stringify({
              data: base64Image,
              timestamp: Date.now()
            }));

            // Set the group icon
            this.groupIcon = base64Image;
          };
          reader.readAsDataURL(imageBlob);
        } catch (error) {
          console.error("Error fetching or caching group icon:", error);
        }
      }
    },
    extractGroupId(group) {
      // Extract the group ID from the group information
      const matches = group.match(/groupid=(\d+)/);
      return matches ? matches[1] : null;
    },
    refreshRecentMembers(groupId) {
      // Fetch and populate the recent members here (same code as before)
      this.getRecentMembers(groupId);
    },
    startCountdown(seconds, groupId) {
      const countdown = document.getElementById("countdown");
      countdown.textContent = seconds;
      this.refreshRecentMembers(groupId);

      const intervalId = setInterval(() => {
        seconds--;

        if (seconds <= 0) {
          clearInterval(intervalId);
          document.getElementById("countdownTimer").style.display = "none";
        } else {
          document.getElementById("countdownTimer").style.display = "block";
          countdown.textContent = seconds;
        }
      }, 1000);
    },
async fetchGroups() {
  let currentPage = 1;
  const limit = 5; // Number of items per page
  let totalPages = 1; // Initialize totalPages to ensure the loop starts

  try {
    // Loop through all pages
    while (currentPage <= totalPages) {
      // Fetch data from the API
      console.log(`Fetching page ${currentPage}...`);
      const response = await axios.get(`https://statify.vmgware.dev/api/allgroups?page=${currentPage}&limit=${limit}`);
      const { groups, totalPages: responseTotalPages, currentPage: responsePage } = response.data;

      // Check if the response contains the expected fields
      if (!groups || !Array.isArray(groups)) {
        console.error('Unexpected response format:', response.data);
        break;
      }

      // Parse and format the fetched groups
      const parsedGroups = groups.map(group => {
        const match = group.match(/^(.*?)\s+Link:\s+(https:\/\/statify\.vmgware\.dev\/dashboard\?groupid=(\d+))$/);
        if (match) {
          return {
            name: match[1].trim(),
            link: match[2],
            groupId: match[3]
          };
        }
        return { name: 'Unknown Group', link: '', groupId: '' };
      });

      // Append the parsed groups to trackedGroups
      this.trackedGroups = [...this.trackedGroups, ...parsedGroups];

      // Update totalPages and currentPage
      totalPages = responseTotalPages;
      currentPage += 1;

      // Log progress
      console.log(`Fetched page ${responsePage}. Total pages: ${totalPages}.`);
    }

    console.log("Finished fetching all groups. Total groups fetched:", this.trackedGroups.length);
  } catch (error) {
    console.error("Error fetching groups:", error);
  }
},
    addGroup() {
      const groupId = this.newGroupId;

      // Check if the group exists on Roblox
      const robloxGroupApiUrl = `https://groups.roblox.com/v1/groups/${groupId}`;
      axios
        .get(robloxGroupApiUrl)
        .then((response) => {
          // Check if the response status is 200 (OK), which means the group exists
          if (response.status === 200) {
            // Now you can add the group
            const url = `https://statify.vmgware.dev/api/groups?groupId=${groupId}`;
            axios
              .get(url)
              .then((response) => {
                // Handle the response data as needed
                console.log("Group data:", response.data);
                // Redirect to the group page
                this.$router.push(`/dashboard?groupid=${groupId}`);
              })
              .catch((error) => {
                console.error("Error adding group:", error);
              });
          } else {
            console.error("Invalid group ID or group does not exist.");
          }
        })
        .catch((error) => {
          console.error("Error checking group:", error);
        });
    },
    fetchData(groupId) {
      axios
        .get(`https://statify.vmgware.dev/api/groupstat?groupId=${groupId}`)
        .then((response) => {
          const groupInfo = response.data.groupInfo.slice(-30);
          this.chartData = groupInfo.map((info) => info.memberCount);

          // Convert timestamps to readable dates
          this.chartLabels = groupInfo.map((info) =>
            moment(info.timestamp).format("YYYY-MM-DD HH:mm:ss")
          );

          const latestGroupInfo = groupInfo[groupInfo.length - 1];

          this.groupInfo = {
            groupName: latestGroupInfo.groupName,
            description: latestGroupInfo.description,
            ownerUserId: latestGroupInfo.ownerUserId,
            created: latestGroupInfo.created,
            timestamp: latestGroupInfo.timestamp,
            hasVerifiedBadge: latestGroupInfo.hasVerifiedBadge,
            memberCount: latestGroupInfo.memberCount,
            link: `https://www.roblox.com/users/${groupInfo.ownerUserId}`,
            groupLink: `${groupId}`,
          };

          console.log(this.groupInfo);

          const latestMemberCount = this.chartData[this.chartData.length - 1];
          this.generateMilestones(latestMemberCount);

          this.renderChart();
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          this.showErrorPage();
        });
    },
    showErrorPage() {
      // Set the error state to true
      this.errorOccurred = true;
    },
    renderChart() {
      Highcharts.chart("chartContainer", {
        chart: {
          type: "line",
          className: "chartcontain",
          backgroundColor: '#151414',
          borderRadius: 10,
        },
        title: {
          text: "Member Count Over Time",
          style: {
            color: "#ffffff",
          },
        },
        xAxis: {
          categories: this.chartLabels,
          labels: {
            style: {
              color: "#ffffff",
            },
          },
        },
        yAxis: {
          title: {
            text: "Member Count",
            style: {
              color: "#ffffff",
            },
          },
          labels: {
            style: {
              color: "#ffffff",
            },
          },
        },
        series: [
          {
            name: "Member Count",
            data: this.chartData,
            color: "#ccc",
            lineColor: "#ccc",
            marker: {
              fillColor: "#ffffff",
              lineColor: "#ccc",
              symbol: "circle",
            },
          },
        ],
        plotOptions: {
          series: {
            marker: {
              enabled: true,
            },
          },
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
      });
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            "downloadSVG",
            "separator",
            "downloadCSV",
            "downloadXLS",
          ],
        },
      },
    },
    generateMilestones(latestMemberCount) {
      this.milestones = [
        {
          label: "1 Million Members",
          value: 1000000,
        },
        {
          label: "2 Million Members",
          value: 2000000,
        },
        {
          label: "3 Million Members",
          value: 3000000,
        },
        {
          label: "Latest Member Count",
          value: latestMemberCount,
        },
      ];
    },
    formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>


<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bulma@1.0.1/css/bulma.min.css");

.chartcontain {
  background-color: #0f0e0e !important;
  color: var(--card-foreground) !important;
  border-color: #212020 !important;
  border-radius: 10px !important;
}

.member-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.profile-image {
  width: 90px !important;
  height: 90px !important;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  margin-right: 10px
}

.username {
  color: #e4e6e5
}

.username,
.username:hover {
  font-size: 14px;
  text-decoration: none
}

.username:hover {
  color: #c9cccb
}


/* Custom CSS for dark mode */
:root {
  --background: #363636;
  --bs-card-bg: #ffffff !important;
  /* Dark background color */
  --foreground: #ffffff;
  /* White foreground text color */
  --card: #3d3d3d !important;
  /* Dark card background color */
  --card-foreground: #ffffff;
  /* Card foreground text color */
  --border: #212020;
  /* Border color */
}

/* Custom CSS for dark mode */
.card {
  background-color: #0f0e0e !important;
  color: var(--card-foreground) !important;
  border-color: #212020 !important;
  border-radius: 10px !important;
}

.navbar {
  background-color: #0f0e0e !important;
}

.footer {
  background-color: #0f0e0e !important;
  height: 10px;
}

.column {
  padding-bottom: 10px !important;
}

.dark-mode .navbar.is-dark {
  background-color: var(--background);
}

.dark-mode .navbar.is-dark .navbar-item,
.dark-mode .navbar.is-dark .navbar-link {
  color: var(--foreground);
}

.dark-mode .navbar.is-dark .navbar-burger span {
  background-color: var(--foreground);
}

.dark-mode .footer {
  background-color: var(--background);
  color: var(--foreground);
}

.dark-mode .has-background-dark {
  background-color: var(--background) !important;
}

.dark-mode .has-text-white {
  color: var(--foreground) !important;
}

.scrollable-list {
  max-height: 200px;
  /* Adjust the height as needed */
  overflow-y: auto;
  padding: 10px;
  border-radius: 8px;
  /* Rounded corners */
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  /* Subtle inner shadow for depth */
}

.scrollable-list::-webkit-scrollbar {
  width: 8px;
  /* Adjust the width of the scrollbar */
}

.scrollable-list::-webkit-scrollbar-thumb {
  background-color: #ccc;
  /* Color of the scrollbar thumb */
  border-radius: 4px;
  /* Rounded corners for the scrollbar thumb */
}

.scrollable-list::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  /* Color of the scrollbar track */
  border-radius: 4px;
  /* Rounded corners for the scrollbar track */
}
</style>
